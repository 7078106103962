<template>
  <v-card 
    v-if="ad!=null"
    height="100%" 
    ref="container" 
    class="ad d-flex flex-column"
  >
    <!-- class="header" -->
    <v-container fluid ref="header" class="d-flex justify-end mr-0">
      <v-hover v-slot="{ hover }">
        <v-text-field
          v-model="configs.title.data"
          :placeholder="configs.title.title"
          outlined
          dense
          hide-details
          @blur="configs.title.focus = false"
          @focus="configs.title.focus = true"
          :class="{ active: hover || configs.title.focus }"
          class="title-input elevation-0 flex-grow-0 ml-3"
        />
      </v-hover>
      <m-btn-long
        v-if="activatable"
        :icon="view.activate.hover&&ad.active||!view.activate.hover&&!ad.active ? icons.mdiPause : icons.mdiPlay"
        :color="view.activate.hover&&ad.active||!view.activate.hover&&!ad.active ? 'grey darken-2' : 'success'"
        :press-color="ad.active ? 'grey darken-2' : 'success'"
        :loading="loading"
        :tooltip="activateConfig.tooltip"
        :duration="activateConfig.duration"
        class="d-flex justify-center align-center"
        @hover="activateHover"
        @cancel="activate(false)"
        @press="activate(true)"
      />
      <v-spacer />

      <v-tooltip top open-delay="250" transition="fade-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-expand-x-transition>
            <v-btn
              v-show="campaign.approval!=0&&ad.id!='new'&&ad.active==2&&!hasChanged"
              icon
              :loading="loading"
              :color="loading&&dialog.context=='remove' ? 'error lighten-1' : 'grey'"
              class="ml-4"
              size="40"
              v-bind="attrs"
              v-on="on"
              @click="toggleDialog(true, 'remove')"
            >
              <v-icon>
                {{ icons.remove }}
              </v-icon>
            </v-btn>
          </v-expand-x-transition>
        </template>
        Excluir {{ getDictionary("ad") }}
      </v-tooltip>

      <v-expand-x-transition>
        <v-btn
          v-if="hasChanged"
          text
          color="grey"
          class="ml-2"
          @click="toggleDialog(true, 'discard')"
        >
          Descartar {{ ad.id == "new" ? "Rascunho" : "Alterações" }}
        </v-btn>
        <!-- <v-btn
            v-else
            text
            color="grey"
            class="ml-2"
            @click="discard"
          >
            Voltar
          </v-btn> -->
      </v-expand-x-transition>

      <v-fade-transition>
        <v-btn
          v-if="hasChanged"
          :loading="loading"
          depressed
          color="accent"
          class="ml-2"
          @click="save"
        >
          {{ ad.id == "new" ? "Criar " + getDictionary("ad") : "Salvar" }}
        </v-btn>
      </v-fade-transition>
    </v-container>

    <div class="config d-flex flex-column">
      <v-container fluid ref="config-header" class="config-header d-flex pa-0">
        <div class="media-tab pa-6 pt-0 flex-shrink-0">
          <media-player
            :url="mediaUrl"
            :format="configs.media.data.format"
            :title="configs.title.data"
            :disabled="disabled"
            rounded
            class="media mt-1"
            @click="toggleMediaView(true)"
          />
        </div>

        <v-card
          outlined
          class="config-tabs overflow-x-auto snap-x"
          ref="config-tabs"
          @wheel.stop
        >
          <v-item-group
            v-model="config"
            active-class="active primary--text"
            mandatory
            class="config-tabs-controller d-flex"
          >
            <v-item
              v-for="(tab, t) in configTabs"
              :key="`config-tab-${t}`"
              v-slot="{ active, toggle }"
              :value="t"
              class="config-tab flex-grow-0 flex-shrink-0"
            >
              <v-hover v-slot="{ hover }" open-delay="500" close-delay="250">
                <v-card
                  flat
                  tile
                  class="config-tab snap-child text-left flex-column align-start justify-start pa-4"
                  :class="{ active: active }"
                  @click="toggle"
                >
                  <header>
                    <v-icon 
                      :color="active ? 'primary' : undefined" 
                      left
                    >
                      {{ icons[tab.icon] }}
                    </v-icon>
                    <span
                      :class="active ? 'primary--text' : undefined"
                      class="config-title text-overline"
                    >
                      {{ tab.title }}
                    </span>
                  </header>
                  <v-menu
                    open-on-hover
                    open-delay="250"
                    close-delay="250"
                    nudge-top="48"
                    nudge-left="16"
                    :close-on-content-click="false"
                    :disabled="!tab.overflowed"
                    transition="fade-transition"
                    content-class="elevation-2"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        class="brief text-subtitle-2 py-2 pl-8 mb-0"
                        v-html="brief[t]"
                        v-on="on"
                        v-bind="attrs"
                      />
                    </template>
                    <v-card
                      flat
                      tile
                      width="286"
                      class="pa-4"
                      :class="active ? 'primary--text' : undefined"
                      style="cursor: pointer;"
                      @click="toggle"
                    >
                      <header>
                        <v-icon 
                          :color="active ? 'primary' : undefined" 
                          left
                        >
                          {{ icons[tab.icon] }}
                        </v-icon>
                        <span
                          :class="active ? 'primary--text' : undefined"
                          class="config-title text-overline"
                          style="font-size: .6875rem !important;"
                        >
                          {{ tab.title }}
                        </span>
                      </header>
                      <p
                        class="brief full text-subtitle-2 py-2 pl-8 mb-0"
                        v-html="briefing[t]"
                      />
                    </v-card>
                  </v-menu>
                </v-card>
              </v-hover>
            </v-item>
          </v-item-group>
        </v-card>
      </v-container>
      <v-container
        fluid
        v-resize="setContentHeight"
        ref="config-content"
        class="config-content d-flex align-stretch pa-0"
      >
        <v-fade-transition>
          <div
            v-if="config == 'location'"
            class="config-controls location-controls fill-height d-flex"
          >
            <location-list
              :city="city"
              :geofences="geofences"
              :selected="selectedGeofences"
              :pois="cityPois"
              :bounds="configs.location.bounds"
              :history="brandCityPois"
              :layer="configs.location.layer"
              :layers="configs.location.layers"
              :height="contentHeight"
              :disabled="!isEditable||disabled||configs.location.disabled"
              width="320"
              class="config-control location-control-list flex-shrink-0"
              @update="updateLocation"
              @select-city="selectCity"
            >
              <template v-slot:cities="{ toggle }">
                <v-expand-transition>
                  <div 
                    v-show="toggle"
                    class="location-control-cities py-2"
                  >
                    <v-subheader class="text-overline primary--text mb-1">
                      <v-icon 
                        left 
                        dense
                        color="primary"
                        class="mr-6"
                      >
                        {{ icons.city }}
                      </v-icon>
                      Cidades:
                    </v-subheader>
                    <m-city-select
                      :cities="cities"
                      :selected="configs.location.city.cities"
                      :disabled="disabled||configs.location.disabled"
                      :selectable="isEditable"
                      multiple
                      clickable
                      mandatory
                      max-width="320"
                      :max-height="contentHeight-96"
                      @message="toggleMessage"
                      @update="updateCities"
                      @click="selectCity"
                    />
                  </div>
                </v-expand-transition>
              </template>
            </location-list>
            <v-divider class="divider" vertical />
            <location-map
              :outline="mapGeofences"
              :pois="mapPois"
              :cities="mapCities"
              :layer-selection="city!=null"
              :layers="layers"
              :layer="configs.location.layer"
              :options="map.options"
              :disabled="!isEditable||disabled||configs.location.disabled"
              ref="map"
              class="config-control location-control-map flex-grow-1"
              @select-city="selectCity"
              @layer-change="onLayerChange"
              @bounds="setBounds"
              @message="toggleMessage"
              @update="updateLocation"
            />
          </div>
          <div
            v-else-if="config == 'period'"
            class="config-controls period-controls fill-height d-flex"
          >
            <period-date
              :selected="configs.period.data.date"
              :started="started"
              :min="campaign.period.start"
              :max="campaign.period.end"
              header
              :height="contentHeight"
              :disabled="disabled||configs.period.disabled"
              width="320"
              class="config-control period-control-date flex-shrink-0"
              @update="updateDate"
            />
            <v-divider vertical />
            <period-hours
              :selected="configs.period.data.hours"
              :started="started"
              :height="contentHeight"
              :disabled="!isEditable||disabled||configs.period.disabled"
              width="100%"
              class="config-control period-control-hours flex-grow-1"
              @update="updateHours"
            />
          </div>
          <div
            v-else-if="config == 'vehicles'"
            class="config-controls vehicles-controls"
          >
            <div
              class="vehicles-control d-flex flex-column align-center justify-center"
            >
              <m-select
                :options="configs.vehicles.options"
                :selected="configs.vehicles.data"
                :disabled="!isEditable||disabled||configs.vehicles.disabled"
                @change="value => updateConfig('vehicles', value)"
              />
            </div>
          </div>
          <div
            v-else-if="config == 'weather'"
            class="config-controls weather-controls"
          >
            <weather-temperature
              :selected="configs.weather.data"
              :hint="configs.weather.hint"
              :disabled="!isEditable||disabled||configs.weather.disabled"
              @update="value => updateConfig('weather', value)"
            />
          </div>
        </v-fade-transition>
      </v-container>
    </div>

    <v-overlay
      v-model="configs.media.toggle"
      absolute
      color="white"
      opacity="0.96"
      @click.stop="toggleMediaView(false)"
      class="media-upload-ad"
    >
      <div class="div-ad">
        <media-upload @update="updateMedia" @toggle="toggleMediaView" />
      </div>
    </v-overlay>

    <v-overlay
      :value="!updated"
      color="white"
      absolute
      opacity=".9"
      class="loading-overlay text-center"
    >
      <loading class="loading mb-4" />
      <span class="d-block text-overline grey--text text--lighten-1">
        Carregando
      </span>
    </v-overlay>

    <v-dialog
      v-model="dialog.toggle"
      content-class="dialog-confirm"
      max-width="320"
      :persistent="dialog.toggle && loading"
    >
      <v-card>
        <v-card-title>
          {{ dialog.title }}
        </v-card-title>

        <v-card-text class="text-body-1 my-4">
          {{ dialog.text }}
        </v-card-text>

        <v-card-actions class="py-4">
          <v-btn
            text
            color="grey"
            :disabled="loading"
            @click="
              dialogAction(
                dialog.actions.cancel.action,
                dialog.actions.cancel.args
              )
            "
          >
            {{ dialog.actions.cancel.text }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="dialog.context == 'remove'"
            :loading="loading"
            text
            color="error"
            @click="
              dialogAction(dialog.actions.deny.action, dialog.actions.deny.args)
            "
          >
            {{ dialog.actions.deny.text }}
          </v-btn>
          <v-btn
            v-else
            color="success"
            :loading="loading"
            @click="
              dialogAction(
                dialog.actions.confirm.action,
                dialog.actions.confirm.args
              )
            "
          >
            {{ dialog.actions.confirm.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style>
.ad {
  overflow: hidden;
}

.ad .header {
  position: relative;
  min-height: 64px;
  z-index: 6;
}

.ad .config {
  height: 100%;
}
.ad .config .config-tabs {
  border-radius: 4px 4px 0 0;
  border-bottom: none !important;
  scroll-padding: 0;
  scroll-margin: 0;
  background: transparent !important;
}
.ad .config .config-tabs-controller {
  width: auto !important;
  background: transparent !important;
}
.ad .config .config-tab {
  border-radius: 4px 4px 0 0 !important;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  text-transform: initial;
  width: 288px;
  will-change: border;
  transition: border 0.15s ease;
  background: transparent !important;
}
.ad .config .config-tab::before {
  background: transparent !important;
}
.ad .config .config-tab.active {
  border-top-color: var(--primary) !important;
  border-bottom-color: white !important;
}
.ad .config .config-tab:not(:last-child) {
  border-right: 1px solid var(--light-border);
}

/* .ad .config .config-tabs .brief.full {
    position: absolute;
    top: 48px;
    background: rgba(255,255,255,.8);
  } */

.ad .config-header {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid var(--light-border);
}

.ad .config .config-title {
  font-size: 0.6875rem !important;
}

.ad .config .config-content {
  position: relative;
  border-top: 3px solid var(--primary);
  margin-top: -4px;
}
/* .ad .config .config-content:before {
    content: '';
    display: block;
    box-shadow: 0px -1px 4px rgba(61, 75, 143, 0.16);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } */
.ad .config .config-content,
.ad .config .config-controls {
  width: 100%;
  height: 100%;
  min-height: 0;
}
.ad .config .config-content .config-controls > * {
  height: 100%;
  max-height: 100%;
  min-height: 0;
}
.ad .config .config-content .location-control-map {
  width: 100%;
  height: 100%;
}
.ad .config .config-content .divider {
  margin: 0 !important;
}

.ad .media {
  position: relative;
  filter: drop-shadow(0px 4px 12px rgba(61, 75, 143, 0.16));
  z-index: 6;
}

.ad .title-input {
  width: 100%;
}
.ad .title-input .v-input__slot {
  box-shadow: none !important;
}
.ad .title-input.v-input--is-focused input {
  color: inherit;
}
.ad .title-input fieldset {
  border: 1px solid transparent;
}
.ad .title-input:hover fieldset {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.ad .title-input.v-input--is-focused fieldset {
  border-color: var(--primary) !important;
}
.ad .title-input input {
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.125em !important;
  text-transform: uppercase !important;
  color: var(--mobees-black);
}

.ad .loading-overlay {
  z-index: 7 !important;
}
.ad .loading-overlay .loading {
  width: 48px;
  height: 48px;
  opacity: 0.8;
}

/* .media-upload-ad{
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  height: 100vh;
  width: 100vw;

} */


/* .div-ad {
  position: relative;

  bottom: 110px;
  right: 100px;
} */
</style>

<script>
import {
  mdiCheck,
  mdiPlay,
  mdiPause,
  mdiDotsVertical,
  mdiDelete,
  mdiCalendarRange,
  mdiMapMarker,
  mdiWeatherSunny,
  mdiTaxi,
  mdiCellphone,
  mdiCarMultiple,
  mdiTrashCan,
  mdiMenuDown,
  mdiCityVariantOutline
} from "@mdi/js";
import { sync } from 'vuex-pathify'
import services from "@/services";
const moment = require("moment");

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    updated: {
      type: Boolean,
      default: false
    },
    refresh: {
      type: Boolean,
      default: false
    },
    campaign: {
      type: Object,
      default: null
    },
    ad: {
      type: Object,
      default: null
    },
    cities: {
      type: Object,
      default: () => {}
    },
    brand: {
      type: Object,
      default: () => {}
    },
    version: {
      type: Number,
      default: 1
    },
    activatable: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object,
      default: null
    },
    admin: {
      type: Boolean,
      default: true
    },
    height: {
      type: [String, Number],
      default: undefined
    }
  },

  data: () => ({
    icons: {
      location: mdiMapMarker,
      period: mdiCalendarRange,
      weather: mdiWeatherSunny,
      vehicles: mdiCarMultiple,
      remove: mdiTrashCan,
      menu: mdiMenuDown,
      city: mdiCityVariantOutline,
      mdiTaxi,
      mdiCellphone,
      mdiCarMultiple,
      mdiCheck,
      mdiPlay,
      mdiPause,
      mdiDotsVertical,
      mdiDelete
    },
    configs: {
      media: {
        toggle: false,
        title: "Upload de mídia",
        hint:
          "Clique na caixa abaixo para selecionar o arquivo ou arraste-o para cá.",
        data: {
          id: null,
          url: null,
          name: null,
          format: null,
          duration: 8
        },
        changed: false,
        disabled: false
      },
      title: {
        title: 'Título',
        data: '',
        focus: false,
        changed: false,
        disabled: false
      },
      location: {
        icon: 'location',
        title: 'Geolocalização',
        layer: 'cities',
        data: {
          geofences: [],
          pois: []
        },
        city: {
          selected: null,
          cities: {},
        },
        layers: ['cities', 'geofences', 'customs', 'pois'],
        bounds: {},
        overflowed: false,
        changed: false,
        disabled: false,
        admin: false
      },
      period: {
        icon: 'period',
        title: 'Período',
        data: {
          date: {
            start: '',
            end: ''
          },
          hours: {
            format: '',
            regex: ''
          }
        },
        overflowed: false,
        changed: false,
        disabled: false,
        admin: false
      },
      vehicles: {
        icon: "vehicles",
        title: "Categorias de Veículo",
        hint: "",
        data: 0,
        options: {
          2: {
            text: "Taxi",
            caption: "Apenas para Taxis",
            value: 2,
            icon: mdiTaxi
          },
          1: {
            text: "Carros de Aplicativo",
            caption: "Apenas em Carros de Aplicativo",
            value: 1,
            icon: mdiCellphone
          },
          0: {
            text: "Todas",
            caption: "Em qualquer Categoria de Veículo",
            value: 0,
            icon: mdiCarMultiple
          }
        },
        overflowed: false,
        changed: false,
        disabled: false,
        admin: true
      },
      weather: {
        icon: "weather",
        title: "Temperatura",
        hint: "Arraste os controles para ajustar a faixa de temperatura",
        data: "",
        overflowed: false,
        changed: false,
        disabled: false,
        admin: true
      }
    },
    config: "location",
    dialog: {
      toggle: false,
      context: null,
      title: "Confirmar",
      text: "",
      actions: {
        deny: {
          text: "Apagar",
          action: "toggleDialog",
          args: [false]
        },
        confirm: {
          text: "Confirmar",
          action: "toggleDialog",
          args: [false]
        },
        cancel: {
          text: "Cancelar",
          action: "toggleDialog",
          args: [false]
        }
      }
    },
    map: {
      geofences: {
        toggle: true,
        data: []
      },
      options: {
        disableDefaultUI: true
        // zoomControl: false,
        // gestureHandling: "none",
      }
    },
    view: {
      activate: {
        hover: false,
        duration: 2000,
        tooltip: {
          top: true,
          disabled: false,
          text: ''
        }
      }
    },
    contentHeight: "100%",
  }),

  computed: {
    toast: sync('app/toast'),

    activateConfig () {
        const config = this.view.activate;
        const active = this.ad.active;
        return _.merge(config, { 
          tooltip: {
            text: active ? 'Pausar' : 'Ativar',
          }
        })
      },

    mediaUrl () {
      const url = this.configs.media.data.url;
      const key = this.refresh ? "?r=" + moment().valueOf() : "";
      return !!url ? url + key : null;
    },

    configTabs () {
      return _.omit(_.omit(this.configs, config => _.has(config, "admin") && config.admin == this.admin), 'title', 'media'
      );
    },

    layers () {
      const layers = [
        { value: 'cities', text: 'Cidades' }, 
        { value: 'geofences', text: 'Bairros' }, 
        { value: 'pois', text: 'Hotspots' },
      ]
      return _.map(layers, layer => {
          return {
            ...layer,
            disabled: layer.value!='cities'&&this.city==null
          }
        })
    },

    zones () {
      return _.mapValues(_.groupBy(this.geofences, "zone.id"), (zone, z) => {
        const g = _.find(zone, ["zone.id", parseInt(z)]);
        return {
          id: g.zone.id,
          title: g.zone.title,
          geofences: zone
        };
      });
    },

    brief () {
      const location =
        this.briefing.location.length > 80
          ? this.truncate(this.briefing.location, 80)
          : this.briefing.location;
      const period =
        this.briefing.period.length > 76
          ? this.truncate(this.briefing.period, 76)
          : this.briefing.period;
      const vehicles = this.briefing.vehicles;
      const weather = this.briefing.weather;

      return {
        location,
        period,
        vehicles,
        weather
      };
    },
    briefing () {
      const location = this.locationBrief;
      const period = this.dateBrief + "<br>" + this.hoursBrief;
      const vehicles = this.vehiclesBrief;
      const weather = this.weatherBrief;

      return {
        location,
        period,
        vehicles,
        weather
      };
    },

    locationBrief () {
      const pois = _.clone(this.configs.location.data.pois);
      let cities = _.cloneDeep(this.configs.location.city.cities);
      let geofences = _.clone(this.configs.location.data.geofences);
      _.each(cities, (city) => {
        let city_geofences = {};
        city.zones = _.reduce(city.zones, (zones, zone, z) => {
          const zone_geofences = _.map(_.clone(zone.geofences), 'id')
          if (_.has(city.zones, z) && _.intersection(zone_geofences, geofences).length == _.size(zone_geofences)) {
            zones.push(zone.title)
            geofences = _.pull(geofences, ...zone_geofences)
          }else{
            city_geofences = { ...city_geofences, ..._.pick(zone.geofences, geofences) };
          }
          return zones;
        }, []);
        city.zones = city.zones.sort();
        city.geofences = _.map(city_geofences, 'title').sort();
        geofences = _.pull(geofences, ..._.map(city_geofences, 'id'));
        city.pois = _.map(_.filter(pois, poi => _.has(poi, 'city')&&!_.isNil(poi.city) ? poi.city.id==city.id : city.id==3), 'title').sort();
        const items = _.concat(city.pois, city.zones, city.geofences);
        city.brief = city.title + (_.isEmpty(items) ? '' : ': '+_.join(items, ', '))
      });
      const brief = _.reduce(cities, (brief, city, i) => {
        return brief == null ? city.brief : brief + (i == cities.length - 1 ? "; e " : "; ") + city.brief;
      }, null);
      // console.log('location brief', brief);
      return brief==null ? '' : brief;
    },

    vehiclesBrief () {
      return this.configs.vehicles.options[this.configs.vehicles.data].caption;
    },

    dateBrief () {
      const p = this.configs.period.data.date;
      let format = 'DD MMM';
      if (moment(p.start).month() == moment(p.end).month()) format = 'DD';
      const start = _.replace(
        this.capitalize(
          moment(p.start)
            .format(format)
            .toString()
        ),
        " ",
        "/"
      );
      const end = _.replace(
        this.capitalize(
          moment(p.end)
            .format("DD MMM")
            .toString()
        ),
        " ",
        "/"
      );
      return start + " – " + end;
    },

    hoursBrief () {
      const dict = {
        Monday: {
          index: 0,
          text: "Seg"
        },
        Tuesday: {
          index: 1,
          text: "Ter"
        },
        Wednesday: {
          index: 2,
          text: "Qua"
        },
        Thursday: {
          index: 3,
          text: "Qui"
        },
        Friday: {
          index: 4,
          text: "Sex"
        },
        Saturday: {
          index: 5,
          text: "Sáb"
        },
        Sunday: {
          index: 6,
          text: "Dom"
        }
      };
      const groupBy = /\(\^(.+?)\$\)/g;
      const splitBy = /\(([^)]+)\)/g;
      const groups = _.map(
        [...this.configs.period.data.hours.regex.matchAll(groupBy)],
        g => {
          const group = [...g[1].matchAll(splitBy)];
          const days = _.split(group[0][1], "|");
          const hours = _.map(_.split(group[1][1], "|"), h => parseInt(h)).sort(
            (a, b) => a - b
          );
          return { days, hours };
        }
      );
      const brief = _.join(
        _.reduce(
          groups,
          (brief, group) => {
            let days = _.sortBy(
              _.map(group.days, d => dict[d]),
              ["index"]
            );
            days =
              _.size(dict) == _.size(days)
                ? "Seg a Dom"
                : days.length == 1
                ? _.first(days).text
                : _.join(
                    _.map(days, d => d.text),
                    ", "
                  );
            const hours =
              group.hours.length == 24
                ? "24h"
                : _.first(group.hours) + "–" + (_.last(group.hours) + 1) + "h";
            brief.push(days + " " + hours);
            return brief;
          },
          []
        ),
        " | "
      );
      return brief;
    },

    weatherBrief () {
      const data = this.configs.weather.data;
      let range = "";
      if (!_.isEmpty(data)) {
        const groupBy = /\(([^)]+)\)/g;
        const groups = _.map([...data.matchAll(groupBy)], g => {
          const degrees = _.map(_.split(g[1], "|"), d => parseInt(d)).sort(
            (a, b) => a - b
          );
          return [_.first(degrees), _.last(degrees) + 1];
        });
        range = _.last(groups);
      }
      return range == ""
        ? "Qualquer Temperatura"
        : "Entre " + range[0] + "°C e " + range[1] + "°C";
    },

    city () {
      const id = this.configs.location.city.selected;
      return _.has(this.cities, id) ? this.cities[id] : null;
    },

    mapCities () {
      const overview = this.city==null;
      const cities = _.mapValues(_.clone(this.configs.location.city.cities), city => {
        return { 
          ...city, 
          marker: overview, 
          selectable: overview,
          selected: this.city!=null&&city.id==this.city.id
        }
      })
      return cities;
    },

    geofences () {
      const city = this.configs.location.city.selected;
      const cities = _.clone(!_.isNil(city)&&_.has(this.cities, city) ? [this.cities[city]] : _.values(this.cities));
      return _.reduce(cities, (geofences, city) => {
        _.each(city.zones, zone => {
          _.each(zone.geofences, g => {
            geofences[g.id] = {
              id: g.id,
              title: g.title,
              url: g.url,
              zone: { id: zone.id, title: zone.title, url: zone.url },
              city: { id: city.id, title: city.title, url: city.url },
              state: _.clone(city.state),
              country: _.clone(city.country)
            };
          });
        });
        return geofences;
      }, {});
    },

    selectedGeofences () {
      let selected = _.clone(this.configs.location.data.geofences);
      const cities = _.intersection(selected, _.map(this.cities, 'id'));
      const geofences = _.clone(this.geofences);
      _.each(cities, city => {
        selected = _.pull(selected, city);
        selected.push(..._.keys(_.pickBy(geofences, ['city.id', city])))
      })
      return _.values(_.pick(geofences, selected));
    },

    mapGeofences () {
      let data;
      const config = this.configs.location;
      const cities = config.layer=='cities' ? config.city.cities : [this.city];

      data = ['cities', 'pois'].indexOf(config.layer)>=0 ? _.map(cities, city => {
        return {
          id: city.id,
          title: city.title,
          url: city.url,
          city: null
        }
      }) : _.map(this.geofences, g => {
        return {
          selected: !_.isNil(_.find(this.selectedGeofences, ['id', g.id])),
          ...g
        };
      });
      // if (layer=='geofences') {
      // } else {
      //   data = _.mapValues(this.zones, (zone, z) => {
      //     return {
      //       selected:
      //         _.size(_.filter(this.selectedGeofences, g => g.zone.id == z)) ==
      //         _.size(zone.geofences),
      //       ...zone
      //     };
      //   });
      // }
      return {
        toggle: true,
        selectable: config.layer!='cities',
        hoverable: ['cities', 'geofences'].indexOf(config.layer)>=0,
        data
      };
    },

    brandCityPois () {
      const city = this.configs.location.city.selected;
      const pois = _.clone(this.brand.pois);
      return _.filter(pois, ['city.id', city])
    },
    cityPois () {
      const city = this.configs.location.city.selected;
      const pois = _.clone(this.configs.location.data.pois);
      return _.filter(pois, ['city.id', city])
    },

    mapPois () {
      const config = this.configs.location;
      const city = config.city.selected;
      const pois = _.clone(config.data.pois);
      // console.log(_.join(_.map(pois, 'id')));

      const data = !_.isNil(city)&&_.has(this.cities, city) ? _.filter(pois, poi => {
        return _.isNil(poi.city) || poi.city.id==city;
      }) : pois;
      return {
        toggle: config.layer=='pois'&&!_.isNil(city),
        point: config.layer=='pois',
        drawable: config.layer=='pois',
        editable: config.layer=='pois'&&this.admin,
        selectable: config.layer=='pois',
        data
      };
    },

    started () {
      return (
        this.ad.active != 2 && moment().isSameOrAfter(this.ad.period.start)
      );
    },

    hasChanged () {
      const changed = _.keys(_.pickBy(this.configs, ["changed", true]));
      if (changed.length > 0) console.log('changed', changed);
      return _.some(this.configs, ["changed", true]);
    },

    disabled () {
      return (this.campaign.approval==0 || ((this.campaign.approval==null||this.campaign.approval==2) && this.version==1 && _.has(this.ad, "edition") && this.ad.edition != null)
      );
    },

    beforeStart () {
      return moment().isBefore(this.configs.period.start);
    },
    afterEnd () {
      return moment().isAfter(this.configs.period.end);
    },
    isEditable () {
      return this.editable && !this.afterEnd;
    }
  },

  watch: {
    ad: {
      immediate: true,
      deep: true,
      handler(ad, before) {
        console.log("ad", ad);
        if (_.isNil(ad)) return;
        this.configs.title.data = ad.title;
        this.configs.media.data = _.assign({}, ad.media);
        if (ad.id=='new'&&_.isEmpty(ad.location.geofences)) {
          this.configs.location.city.cities = _.cloneDeep(this.cities);
          this.configs.location.data.geofences = _.map(this.cities, 'id');
        }else{
          this.configs.location.city.cities = this.getCitiesByGeofences(ad.location.geofences, ad.location.pois);
          this.configs.location.data.geofences = _.clone(_.uniq(ad.location.geofences));
        }
        this.configs.location.data.pois = _.clone(ad.location.pois);
        
        this.configs.period.data.date = ad.period;
        if (!this.started) {
          const campaign = this.campaign;
          let start = moment().isAfter(ad.period.start) ? moment().format('YYYY-MM-DD') : moment(campaign.period.start).isAfter(ad.period.start) ? campaign.period.start : ad.period.start;
          start = moment(campaign.period.start).isAfter(ad.period.start)
            ? campaign.period.start
            : start;
          let end = moment(start).isAfter(ad.period.end)
            ? moment().add(moment(ad.period.end).diff(ad.period.start, 'd'), 'd').format('YYYY-MM-DD')
            : ad.period.end;
          end = moment(campaign.period.end).isBefore(end)
            ? campaign.period.end
            : end;
          if (ad.period.start!=start||ad.period.end!=end) this.updateDate({ start, end });
        }
        this.configs.period.data.hours = ad.recurrence;
        this.configs.vehicles.data = ad.vehicles;
        this.configs.weather.data = ad.temperature;

        if (_.isNil(before)||before.id != ad.id) this.autoSelectLayer();

        _.each(this.configs, (config, c) => {
          config.changed = (ad.id == "new" && !(c == "media" && config.data.url == null))||false;
        });
      }
    },
    version(v) {
      console.log("version", v);
    },

    "configs.title.data": {
      handler(title) {
        this.configs.title.changed = !_.isEqual(title, this.ad.title);
      }
    },
    "configs.media.data": {
      deep: true,
      handler(media) {
        this.configs.media.changed = !_.isNil(media.url) && !_.isEqual(media.url, this.ad.media.url);
      }
    },
    "configs.location.data": {
      deep: true,
      handler(location) {
        this.configs.location.changed =
          !_.isEqual(location.geofences, this.ad.location.geofences) ||
          !_.isEqual(location.pois, this.ad.location.pois);
      }
    },
    "configs.period.data": {
      deep: true,
      handler(period) {
        const changed =
          !_.isEqual(period.date, this.ad.period) ||
          !_.isEqual(period.hours, this.ad.recurrence);
        this.configs.period.changed = changed;
      }
    },
    "configs.vehicles.data": {
      handler(vehicles) {
        console.log("vehicle", vehicles, this.ad.vehicles);
        this.configs.vehicles.changed = !_.isEqual(vehicles, this.ad.vehicles);
      }
    },
    "configs.weather.data": {
      handler(temperature) {
        console.log("weather", temperature, this.ad.temperature);
        this.configs.weather.changed = !_.isEqual(
          temperature,
          this.ad.temperature
        );
      }
    },
    brief: {
      immediate: true,
      deep: true,
      handler(brief) {
        _.each(brief, (text, k) => {
          this.configs[k].overflowed = this.briefing[k] != text;
        });
      }
    },
    loading(b) {
      if (this.dialog.toggle) {
        this.dialog.loading = b;
        this.dialog.toggle = b;
      }
    }
  },

  filters: {
    truncate (text, max) {
      max = _.isNil(max) ? 80 : max;
      return text.length > max ? text.substring(0, max) + "..." : text;
    },
    cityTitle (id, cities) {
      return _.has(cities, id) ? cities[id].title : '';
    }
  },

  methods: {
    ...services,

    toggleMediaView (b) {
      this.configs.media.toggle = b;
    },

    toggleConfig (config) {
      this.config = config;
    },

    toggleMessage (message) {
      this.$emit("message", message);
    },

    updateConfig (config, value) {
      this.configs[config].data = value;
    },

    updateLocation (location) {
      let { prop, value } = location
      console.log("location", value);
      const city = this.configs.location.city.selected;
      let before = _.clone(this.configs.location.data[prop]);
      if (prop=='geofences') {
        const implicit = _.indexOf(before, city)>=0;
        let geofences = _.map(_.pickBy(this.geofences, ['city.id', city]), 'id');
        geofences = _.intersection(before, geofences).length==0 ? [] : _.intersection(before, geofences);
        const add = _.difference(value, geofences);
        const remove = _.concat(_.difference(geofences, value), ...(implicit ? [city] : []));
        value = [...before.filter(g => { 
          return _.indexOf(remove, g)<0;
        }), ...add];
      }else{
        const pois = _.keyBy(_.filter(before, ['city.id', city]), 'id');
        if (_.size(pois)>_.size(value)) {
          const remove = _.difference(_.map(pois, 'id'), _.map(value, 'id'));
          value = before.filter(p => _.indexOf(remove, p.id)<0)
        }else{
          value = _.values({ ..._.keyBy(before, 'id'), ..._.keyBy(value, 'id') })
        }
      }
      // TODO: check if has at least 1 geofence/poi
      this.configs.location.data[prop] = value;
    },
    updateCities (cities) {
      const before = _.clone(this.configs.location.city.cities);
      let geofences = this.configs.location.data.geofences;
      if (_.size(before)<_.size(cities)) {
        const add = _.difference(_.keys(cities), _.keys(before));
        _.each(add, c => {
          geofences.push(parseInt(c));
        })
      }else{
        const remove = _.difference(_.keys(before), _.keys(cities));
        _.each(remove, c => {
          const city = this.cities[c];
          const ids = [city.id, ..._.map(_.pickBy(this.geofences, ['city.id', city.id]), 'id')];
          this.configs.location.data.geofences = geofences.filter(g => _.indexOf(ids, g)<0);
        });
      }
      this.configs.location.city.cities = cities;
    },
    selectCity (id) {
      this.configs.location.city.selected = id;
      this.autoSelectLayer();
    },

    setBounds (bounds) {
      console.log(bounds);
      this.configs.location.bounds = bounds;
    },

    updateDate (period) {
      this.configs.period.data.date = period;
    },

    updateHours (hours) {
      this.configs.period.data.hours = hours;
    },

    updateMedia({ url, filename, format }) {
      this.configs.media.data.url = url;
      this.configs.media.data.name = filename.normalize("NFD").replace(/\p{Diacritic}/gu, "");
      this.configs.media.data.format = format.normalize("NFD").replace(/\p{Diacritic}/gu, "");
    },

    getCitiesByGeofences (geofences, pois) {
      const allowed = _.cloneDeep(this.cities);
      let selected = _.reduce(allowed, (selected, city) => {
        const city_geofences = _.flatten(_.map(city.zones, zone => _.map(zone.geofences, 'id')));
        const city_pois = _.filter(pois, poi => _.has(poi, 'city') ? poi.city.id==city.id : city.id==3);
        if (_.indexOf(geofences, city.id)>=0||(_.intersection(geofences, city_geofences).length>0||_.size(city_pois)>0)) {
          selected[city.id] = _.clone(city);
        }
        return selected
      }, {})
      if (_.isEmpty(selected)&&_.size(allowed)==1) {
        const city = _.first(_.values(allowed));
        selected[city.id] = city;
      }
      return selected;
    },

    autoSelectLayer () {
      if (_.size(this.configs.location.city.cities)==1&&this.configs.location.city.selected==null) {
        this.configs.location.city.selected = _.map(this.configs.location.city.cities, 'id')[0];
      }
      if (this.configs.location.city.selected!=null) {
        this.configs.location.layer = _.size(this.configs.location.data.pois)>0 ? 'pois' : 'geofences';
      }else{
        if (this.configs.location.layer!='cities') this.configs.location.layer = 'cities'
      }
    },

    onLayerChange (layer) {
      this.configs.location.layer = layer;
      if (layer=='cities') {
        this.configs.location.city.selected = null;
      }
    },

    processChanges () {
      return _.mapValues(
        _.pickBy(this.configs, config => {
          return this.ad.id == "new" || config.changed;
        }),
        c => c.data
      );
    },

    truncate (text, max) {
      max = _.isNil(max) ? 80 : max;
      return text.length > max ? text.substring(0, max) + "..." : text;
    },

    save () {
      const location = this.configs.location;
      if (_.isEmpty(location.data.pois)&&_.isEmpty(location.data.geofences)) {
        location.data.geofences = _.map(location.city.cities, 'id');
      }
      const data = _.assign(this.processChanges(), { id: this.ad.id });
      this.$emit("save", data);
    },
    discard () {
      this.$emit("discard");
    },
    remove () {
      console.log("remove", this.ad.id);
      this.$emit("remove", this.ad.id);
    },
    activate (confirm) {
      console.log(confirm)
      if (confirm) {
        console.log("activate", this.ad.id);
        this.$emit("activate", this.ad.id);
        this.toggleToast(
          false,
          '',
          0,
          false
        );
      }else{
        const msg = this.ad.active ? 'pausar' : 'ativar'
        this.toggleToast(
          true,
          `Mantenha pressionado para ${msg}`,
          7000,
          false
        );
      }
    },

    activateHover (b) {
      this.view.activate.hover = b;
    },

    setContentHeight () {
      this.$nextTick(() => {
        const container = _.has(this.$refs, "container")
          ? this.$refs["container"].$el.clientHeight
          : "100%";
        const header = _.has(this.$refs, "header")
          ? this.$refs["header"].clientHeight
          : "100%";
        const configHeader = _.has(this.$refs, "config-header")
          ? this.$refs["config-header"].clientHeight
          : "100%";
        // const h = _.has(this.$refs, 'config-content') ? this.$refs['config-content'].clientHeight : '100%';
        if (this.contentHeight == "100%" && container != "100%") {
          this.contentHeight = container - header - configHeader;
          console.log("Ad height", container);
        }
      });
    },

    toggleDialog (b, context) {
      const dialog = this.dialog;
      dialog.context = context;
      const ad_dict = this.getDictionary("ad");
      switch (context) {
        case "remove":
          dialog.title = `Apagar ${ad_dict}`;
          dialog.text = `Tem certeza que deseja apagar o ${ad_dict} ${this.configs.title.data}?`;
          dialog.actions.deny.text = "Apagar";
          dialog.actions.deny.action = "remove";
          dialog.actions.deny.args = [];

          break;
        case "discard":
          dialog.title = "Descartar Alterações";
          dialog.text = `Existem alterações que não foram salvas no ${ad_dict} ${this.configs.title.data}. Deseja descartá-las?`;
          dialog.actions.confirm.text = "Descartar";
          dialog.actions.confirm.action = "discard";
          dialog.actions.confirm.args = [];

          break;
      }
      this.dialog.toggle = b;
    },
    dialogAction (fn, args) {
      this[fn](...args);
    },
    alert (message) {
      this.toggleToast(
        true,
        `Mantenha pressionado para ${msg}`,
        7000,
        false
      );
    }
  },

  updated () {
    this.$nextTick(() => {
      this.setContentHeight();
    });
  },

  mounted () {
    this.$nextTick(() => {
      this.$refs["config-tabs"].scrollLeft = 0;
    });
  },

  components: {
    MediaPlayer: () => import("@/components/campaigns/MediaPlayer"),
    MediaUpload: () => import("@/components/campaigns/MediaUpload"),
    LocationList: () => import("@/components/campaigns/LocationList"),
    LocationMap: () => import("@/components/mMap"),
    MSelect: () => import("@/components/mSelect"),
    PeriodDate: () => import("@/components/campaigns/PeriodDate"),
    PeriodHours: () => import("@/components/campaigns/PeriodHours"),
    WeatherTemperature: () =>
      import("@/components/campaigns/WeatherTemperature"),
    Loading: () => import("@/components/IconLoading"),
    MBtnLong: () => import('@/components/mBtnLong'),
    MCitySelect: () => import('@/components/mCitySelect')
  }
};
</script>
